<template>
  <div class="attachment-document">
    <div class="row">
      <div class="col-md-12">
        
        <div class="card">
          <div class="card-header" style="background: #fff">
            Attachment Document
            <router-link class="float-right" to="/media/media-library">
              <i class="mdi mdi-window-close font-size-18 text-dark"></i>
            </router-link>
          </div>
          <div class="row" v-if="info">
            <div class="col-md-7">
              <div class="card-body text-center">
                <div v-if="info.type === 'image'">
                  <img :src="`${info.path}`" alt="image" class="img-fluid" />
                  <div class="w-100">
                    <button
                      class="btn btn-success btn-sm mt-2"
                      v-b-modal.edit-image
                    >
                      Edit Image
                    </button>
                  </div>
                </div>
                <div v-if="info.type === 'video'">
                  <video controls>
                    <source :src="`${info.path}`" />
                  </video>
                </div>

                <div v-if="info.type === 'audio'">
                  <audio controls>
                    <source :src="`${info.path}`" />
                  </audio>
                </div>
                <div v-if="info.type === 'doc'">
                  <iframe :src="`${info.path}`"></iframe>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <div
                class="h-100 card-body"
                style="
                  background: rgba(247, 247, 250, 0.45);
                  border-right: 2px solid #fff;
                "
              >
                <label class="w-100 font-size-12 mb-0"
                  ><b>File name:</b> {{ info.name }}</label
                >
                <label class="w-100 font-size-12 mb-0"
                  ><b>Folder:</b> {{ info.folder }}</label
                >
                <label class="w-100 font-size-12 mb-0"
                  ><b>File type:</b> {{ info.type }}</label
                >
                <label class="w-100 font-size-12 mb-0"
                  ><b>Uploaded on:</b> {{ info.created_at }}</label
                >
                <label class="w-100 font-size-12 mb-0"
                  ><b>Updated at:</b> {{ info.updated_at }}</label
                >
                <hr />

                <div class="row pb-2">
                  <div class="col-5 text-right pt-1">
                    <span class="font-size-13">URL</span>
                  </div>
                  <div class="col-7 pl-0">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      readonly
                      :value="`${info.path}`"
                    />
                  </div>
                </div>
                <div class="row pb-2 pt-1">
                  <div class="col-5 text-right pt-1">
                    <span class="font-size-13">Title</span>
                  </div>
                  <div class="col-7 pl-0">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="info.title"
                    />
                  </div>
                </div>
                <div class="row pt-1">
                  <div class="col-5 text-right pt-1">
                    <span class="font-size-13">Description</span>
                  </div>
                  <div class="col-7 pl-0">
                    <textarea
                      class="form-control"
                      rows="4"
                      v-model="info.description"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="border-top: 1px solid #e9ecef" class="p-3">
            <div class="float-right">
              <a
                href="javascript:void(0);"
                v-on:click="deleteFile(info.id)"
                class="btn btn btn-add btn-dark"
                >Remove
              </a>

              <button
                type="button"
                v-on:click="update()"
                class="btn btn btn-add btn-primary"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="edit-image" scrollable title="Edit Image">
      <form @submit.prevent="cropImage">
        <div v-if="info">
          <select
            class="custom-select form-control mb-2"
            v-model="folder"
            id="firstName"
            name="firstName"
            :class="{
              'is-invalid': submitted && $v.folder.$error,
            }"
          >
            <option value="" selected>Select Folder</option>
            <option value="pages">Pages</option>
            <option value="products">Products</option>
            <option value="brands">Brands</option>
          </select>
          <div v-if="submitted && !$v.folder.required" class="invalid-feedback">
            Folder is required
          </div>
          <cropper
            ref="cropper"
            class="example-cropper"
            :src="`${info.path}`"
            :stencil-props="{
              movable: true,
              resizable: true,
            }"
          />
        </div>
        <div class="form-group float-right mb-1">
          <button class="btn btn-danger btn-sm mt-3">Upload</button>
        </div>
      </form>
      <!-- <template #modal-footer="{}">
        <b-button size="sm" variant="danger" @click="cropImage">
          Upload
        </b-button>
      </template> -->
    </b-modal>
  </div>
</template>


<script>
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import axios from "axios";

import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
const imageApiURL = process.env.VUE_APP_IMAGE_URL;
const mediaApi = process.env.VUE_APP_MEDIA_SERVICE;
export default {
  name: "attachment-document",
  components: {
    
    Cropper,
  },
  data() {
    return {
      typeOfFile: "",
      info: null,
      
      imageApiURL,
      body: "",
      folder: "",
      submitted: false,
    };
  },
  validations: {
    folder: { required },
  },
  computed: {},
  mounted() {
    this.list();
  },
  methods: {
    list() {
      
      axios
        .get(`${mediaApi}/files/${this.$route.params.id}`)
        .then((response) => {
          this.info = response.data.data[0];
          this.info.created_at = moment(String(this.info.created_at)).format(
            "MM/DD/YYYY"
          );
          this.info.updated_at = moment(String(this.info.updated_at)).format(
            "MM/DD/YYYY"
          );
          this.info.path = `${imageApiURL}/${this.info.path}`;
          
          this.fileType();
        });
    },
    async update() {
      if (this.info.description === null || this.info.description === "") {
        this.body = { title: this.info.title };
      } else
        this.body = {
          title: this.info.title,
          description: this.info.description,
        };
      
      await axios
        .post(`${mediaApi}/file/update/${this.$route.params.id}`, this.body)
        .then((response) => {
          
          if (response.data.code == 200) {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              type: "success",
              title: " ",
              text: response.data.messages,
            });
          } else {
            this.$swal({
              type: "error",
              title: "Oops...",
              text: response.data.messages,
            });
          }
        })
        .catch((error) => {
          
          this.$swal({
            type: "error",
            title: "Oops...",
            text: error.response.data.messages,
          });
        });
    },
    deleteFile(id) {
      let body = { id: id };
      this.$swal({
        title: "Delete this File?",
        text: "Are you sure? You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios.post(`${mediaApi}/file/delete`, body).then((response) => {
            
            if (response.data.code == 200) {
              this.$router.push("/media/media-library");
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                type: "success",
                title: " ",
                text: response.data.messages,
              });
            } else {
              this.$swal({
                type: "error",
                title: "Oops...",
                text: response.data.messages,
              });
            }
          });
        }
      });
    },
    fileType() {
      this.typeOfFile = this.info.name.split(".").splice(-1).toString();
    },

    cropImage() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      let folder = this.folder 
      const { canvas } = this.$refs.cropper.getResult();
      canvas.toBlob((result) => {
        let formData = new FormData();
        formData.append("file", result);
         formData.append("folder", folder );
        
        axios
          .post((`${mediaApi}/file/upload`),
        
           formData,folder
          )
          .then(
            function (response) {
              
              if (response.data.code == 200) {
                this.$router.push("/media/media-library");
                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  type: "success",
                  title: " ",
                  text: "Successfully Uploaded",
                });
              } else {
                this.$swal({
                  type: "error",
                  title: "Oops...",
                  text: "The given data was invalid",
                });
              }
            }.bind(this)
          )
          .catch(
            function (error) {
              // handle error response here
            }.bind(this)
          );
      }, "image/jpeg");
    },
  },
};
</script>
<style scoped>
video {
  max-width: 100%;
}
iframe {
  height: 421px;
  border: none;
}
.modal-footer {
  display: none !important;
}
</style>